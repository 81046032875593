// AppContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { cacheService } from './cacheService';
import { useNavigate } from 'react-router-dom';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => localStorage.getItem("dark") === "1");
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [isAuthChecking, setIsAuthChecking] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [results, setResults] = useState(null);
  const [userSubmittedImage, setUserSubmittedImage] = useState(null);
  const [userSubmittedPrompt, setUserSubmittedPrompt] = useState(null);
  
  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDark);
  }, [isDark]);

  const getUserEmail = async (user) => {
    let email = null;
    
    if (user.signInDetails?.loginId) {
      email = user.signInDetails.loginId;
    } else if (user.username && user.username.includes('@')) {
      email = user.username;
    } else if (user.attributes?.email) {
      email = user.attributes.email;
    }

    if (!email) {
      try {
        const session = await fetchAuthSession();
        const claims = session.tokens?.idToken?.payload;
        if (claims?.email) {
          email = claims.email;
        }
      } catch (error) {
        console.error('Error getting email from session:', error);
      }
    }

    return email;
  };

  const getAuthToken = useCallback(async () => {
    if (!isAuthenticated) return null;
    try {
      const session = await fetchAuthSession();
      const token = session.tokens?.accessToken?.toString();
      setAuthToken(token);
      return token;
    } catch (error) {
      console.error('Error getting auth token:', error);
      return null;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getAuthToken();
    } else {
      setAuthToken(null);
    }
  }, [isAuthenticated, getAuthToken]);

  const clearAllUserData = useCallback(async () => {
    try {
      await cacheService.invalidateAllCaches();
      
      setIsAuthenticated(false);
      setUserEmail(null);
      setAuthToken(null);
      setResults(null);
      setUserSubmittedImage(null);
      setUserSubmittedPrompt(null);
    } catch (error) {
      console.error('Error clearing user data:', error);
      setIsAuthenticated(false);
      setUserEmail(null);
      setAuthToken(null);
      setResults(null);
      setUserSubmittedImage(null);
      setUserSubmittedPrompt(null);
    }
  }, []);

  const handleSignIn = useCallback(async (shouldNavigate = false) => {
    try {
      await cacheService.invalidateAllCaches();
      
      const user = await getCurrentUser();
      if (!user) {
        throw new Error('No user found');
      }

      const email = await getUserEmail(user);
      
      if (!email) {
        console.warn('No email found for user, may affect functionality');
      }

      setIsAuthenticated(true);
      setUserEmail(email);
      
      const session = await fetchAuthSession();
      const token = session.tokens?.accessToken?.toString();
      setAuthToken(token);
      
      // Only navigate if this is a fresh sign-in
      if (shouldNavigate) {
        navigate('/sketch');
      }
      
    } catch (error) {
      console.error('Error during sign in:', error);
      await clearAllUserData();
    } finally {
      setIsAuthChecking(false);
    }
  }, [clearAllUserData, navigate]);

  const checkAuthStatus = useCallback(async () => {
    setIsAuthChecking(true);
    try {
      // Pass false to prevent navigation on page reload
      await handleSignIn(false);
    } catch (error) {
      console.error('Error checking auth status:', error);
      await clearAllUserData();
    } finally {
      setIsAuthChecking(false);
    }
  }, [handleSignIn, clearAllUserData]);

  useEffect(() => {
    checkAuthStatus();

    const unsubscribe = Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
        case 'federatedSignIn':
          // Pass true to navigate on fresh sign-in
          await handleSignIn(true);
          break;
        case 'signOut':
          await clearAllUserData();
          setIsAuthChecking(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        case 'federatedSignIn_failure':
          console.error('Auth failure:', data);
          await clearAllUserData();
          setIsAuthChecking(false);
          break;
        case 'tokenRefresh':
          await getAuthToken();
          break;
        case 'tokenRefresh_failure':
          console.error('Token refresh failure:', data);
          await checkAuthStatus();
          break;
        default:
          break;
      }
    });

    return () => unsubscribe();
  }, [checkAuthStatus, clearAllUserData, handleSignIn, getAuthToken]);

  const toggleDarkMode = useCallback(() => {
    setIsDark(prevIsDark => {
      const newIsDark = !prevIsDark;
      localStorage.setItem("dark", newIsDark ? "1" : "");
      return newIsDark;
    });
  }, []);

  const updateAuthStatus = useCallback(async (status, email = null) => {
    try {
      await cacheService.invalidateAllCaches();
      
      if (!status) {
        await clearAllUserData();
      } else {
        setIsAuthenticated(status);
        if (email) {
          setUserEmail(email);
        }
      }
    } catch (error) {
      console.error('Error updating auth status:', error);
      await clearAllUserData();
    } finally {
      setIsAuthChecking(false);
    }
  }, [clearAllUserData]);

  const handleLogout = useCallback(async () => {
    try {
      await signOut({ global: true });
      await clearAllUserData();
    } catch (error) {
      console.error('Error during logout:', error);
      await clearAllUserData();
    }
  }, [clearAllUserData]);

  const clearResults = useCallback(() => {
    setResults(null);
    setUserSubmittedImage(null);
    setUserSubmittedPrompt(null);
  }, []);

  return (
    <AppContext.Provider value={{
      isDark,
      toggleDarkMode,
      isAuthenticated,
      isAuthChecking,
      updateAuthStatus,
      handleLogout,
      authToken,
      getAuthToken,
      userEmail,
      checkAuthStatus,
      results,
      setResults,
      userSubmittedImage,
      setUserSubmittedImage,
      userSubmittedPrompt,
      setUserSubmittedPrompt,
      clearResults
    }}>
      {children}
    </AppContext.Provider>
  );
};