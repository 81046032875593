import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Check, Factory, TrendingUp, Target, Scissors, Store, Upload, ChevronRight, ChevronLeft, RefreshCw } from 'lucide-react';
import { AppContext } from '../AppContext';
import useAPIService from './APIService';
import './styles/ManuContact.css';

const ManuContact = () => {
  const { isDark } = useContext(AppContext);
  const apiService = useAPIService();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    country: '',
    garment_specializations: '',
    fabric_specializations: '',
    bio: '',
    images: null
  });
  const [previewFiles, setPreviewFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const resetForm = () => {
    setStep(1);
    setFormData({
      name: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      garment_specializations: '',
      fabric_specializations: '',
      bio: '',
      images: null
    });
    setPreviewFiles([]);
    setError(null);
  };

  const iconColors = {
    store: { light: '#0d6efd', dark: '#ffffff' },
    target: { light: '#10b981', dark: '#ffffff' },
    factory: { light: '#f97316', dark: '#ffffff' },
    scissors: { light: '#ef4444', dark: '#ffffff' }
  };

  const validateForm = (currentStep) => {
    switch (currentStep) {
      case 1:
        if (!formData.name || !formData.email || !formData.phone || !formData.address || !formData.country) {
          setError('Please fill in all required fields');
          return false;
        }
        if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          setError('Please enter a valid email address');
          return false;
        }
        break;
      case 2:
        if (!formData.garment_specializations || !formData.fabric_specializations || !formData.bio) {
          setError('Please provide garment specializations, fabric specializations, and company bio');
          return false;
        }
        break;
      case 3:
        if (!formData.images) {
          setError('Please upload your product images');
          return false;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/zip') {
      setFormData({ ...formData, images: file });
      setPreviewFiles([file.name]);
      setError(null);
    } else {
      setError('Please upload a ZIP file');
    }
  };

  const handleSubmit = async () => {
    if (!validateForm(step)) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const submitData = new FormData();
      Object.keys(formData).forEach(key => {
        submitData.append(key, formData[key]);
      });

      const response = await apiService.SubmitManufacturerForm(submitData);
      
      if (response) {
        setStep(4);
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = 'An error occurred during submission. Please try again.';
      
      if (error.response && error.response.data) {
        const { error: serverError, code } = error.response.data;
        
        switch (code) {
          case 'NO_FILE':
            errorMessage = 'Please upload a ZIP file containing your product images.';
            break;
          case 'INVALID_FILE_TYPE':
            errorMessage = 'The uploaded file must be a ZIP file.';
            break;
          case 'NO_VALID_IMAGES':
            errorMessage = 'Your ZIP file does not contain any valid images. Please ensure it contains JPG or PNG images of your products.';
            break;
          case 'INVALID_ZIP_CONTENT':
            errorMessage = serverError || 'The ZIP file content is invalid. Please check the file and try again.';
            break;
          case 'DUPLICATE_NAME':
            errorMessage = 'A manufacturer with this name already exists. Please use a different name.';
            setStep(1);
            break;
          default:
            errorMessage = serverError || errorMessage;
        }
      }
      
      setError(errorMessage);
      
      if (['NO_FILE', 'INVALID_FILE_TYPE', 'NO_VALID_IMAGES', 'INVALID_ZIP_CONTENT'].includes(error.response?.data?.code)) {
        setFormData(prev => ({ ...prev, images: null }));
        setPreviewFiles([]);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextStep = () => {
    if (validateForm(step)) {
      setStep(step + 1);
    }
  };

  const renderFormStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="form-step">
            {error && <div className={`error-message error-message--${isDark ? 'dark' : 'light'}`}>{error}</div>}
            <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Manufacturer Name *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className={`form-input form-input--${isDark ? 'dark' : 'light'}`}
              placeholder="Enter your company name"
              required
            />
            
            <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Email Address *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`form-input form-input--${isDark ? 'dark' : 'light'}`}
              placeholder="Enter your business email"
              required
            />
            
            <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Phone Number *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className={`form-input form-input--${isDark ? 'dark' : 'light'}`}
              placeholder="Enter your business phone"
              required
            />
            
            <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Address *</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className={`form-input form-input--${isDark ? 'dark' : 'light'}`}
              placeholder="Enter your business address"
              required
            />
            
            <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Country *</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className={`form-input form-input--${isDark ? 'dark' : 'light'}`}
              placeholder="Enter your country"
              required
            />
          </div>
        );
      
        case 2:
            return (
              <div className="form-step">
                {error && <div className={`error-message error-message--${isDark ? 'dark' : 'light'}`}>{error}</div>}
                <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Company Bio *</label>
                <textarea
                  name="bio"
                  value={formData.bio}
                  onChange={handleInputChange}
                  className={`form-textarea form-textarea--${isDark ? 'dark' : 'light'}`}
                  placeholder="Tell us about your company, history, and what makes you unique"
                  required
                />
                
                <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Garment Specializations *</label>
                <textarea
                  name="garment_specializations"
                  value={formData.garment_specializations}
                  onChange={handleInputChange}
                  className={`form-textarea form-textarea--${isDark ? 'dark' : 'light'}`}
                  placeholder="List all garment types you manufacture (e.g., dresses, shirts, pants, etc.)"
                  required
                />
                
                <label className={`form-label form-label--${isDark ? 'dark' : 'light'}`}>Fabric Specializations *</label>
                <textarea
                  name="fabric_specializations"
                  value={formData.fabric_specializations}
                  onChange={handleInputChange}
                  className={`form-textarea form-textarea--${isDark ? 'dark' : 'light'}`}
                  placeholder="List all fabric types you work with (e.g., cotton, silk, denim, etc.)"
                  required
                />
              </div>
            );
      
      case 3:
        return (
          <div className="form-step">
            {error && <div className={`error-message error-message--${isDark ? 'dark' : 'light'}`}>{error}</div>}
            <div className={`info-alert info-alert--${isDark ? 'dark' : 'light'}`}>
              <p>Please provide photos of your manufactured clothing in a ZIP file. Each photo should be named according to what the garment is (e.g., "summer-dress.jpg").</p>
            </div>
            
            <div className={`file-upload-box file-upload-box--${isDark ? 'dark' : 'light'}`}>
              <input
                type="file"
                accept=".zip"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="zip-upload"
              />
              <label htmlFor="zip-upload" style={{ cursor: 'pointer' }}>
                <Upload size={24} className="mb-2" />
                <p>Click to upload ZIP file containing your garment photos</p>
                {previewFiles.length > 0 && (
                  <p className="mt-2 text-sm">Selected: {previewFiles[0]}</p>
                )}
              </label>
            </div>
          </div>
        );
      
        case 4:
            return (
              <div className="form-step form-step--last">
                <div className="text-center">
                  <div className="w-16 h-16 mx-auto mb-4 bg-green-100 rounded-full flex items-center justify-center">
                    <Check size={32} className="text-green-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Submission Successful!</h3>
                  <p className="mb-6">Thank you for submitting. We'll review your submission and get back to you soon.</p>
                  <button 
                    className={`button button--secondary button--${isDark ? 'dark' : 'light'} mx-auto flex items-center gap-2 justify-center`}
                    onClick={resetForm}
                  >
                    <RefreshCw size={20} />
                    Restart
                  </button>
                </div>
              </div>
            );
          default:
            return null;
        }
      };

  return (
    <div className={`gradient-background gradient-background--${isDark ? 'dark' : 'light'}`}>
      <Container className="styled-container">
        <div className="header-section">
          <div className={`accent-circle accent-circle--${isDark ? 'dark' : 'light'} top-right`} />
          <div className={`accent-circle accent-circle--${isDark ? 'dark' : 'light'} bottom-left`} />
          <h1 className={`header-text header-text--${isDark ? 'dark' : 'light'}`}>
          Join Our Manufacturers
          </h1>
          <p className={`subheader-text subheader-text--${isDark ? 'dark' : 'light'}`}>
            Connect with fashion brands through our AI-powered platform and grow your business
          </p>

          <div className="network-stats">
            <div className={`stat-item stat-item--${isDark ? 'dark' : 'light'}`}>
              <div className="number">5,000+</div>
              <div className="label">Active Manufacturers</div>
            </div>
            <div className={`stat-item stat-item--${isDark ? 'dark' : 'light'}`}>
              <div className="number">AI</div>
              <div className="label">Smart Matching</div>
            </div>
            <div className={`stat-item stat-item--${isDark ? 'dark' : 'light'}`}>
              <div className="number">24/7</div>
              <div className="label">Brand Discovery</div>
            </div>
          </div>
        </div>

        <Row className="g-4">
          <Col lg={6}>
            <div className={`content-section content-section--${isDark ? 'dark' : 'light'}`}>
              <div className={`process-section process-section--${isDark ? 'dark' : 'light'}`}>
              <h3 className={`process-title process-title--${isDark ? 'dark' : 'light'}`}>
                <span className="title-underline">How POMU Works</span>
                </h3>
                <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
                Our AI matches your capabilities with brands seeking your expertise
                </p>
                <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
                Join 5,000+ manufacturers getting direct brand inquiries. Your profile reaches qualified clients 24/7.
                </p>
              </div>

              <h2 className={`section-title section-title--${isDark ? 'dark' : 'light'}`}>
                <span className="title-underline">AI-Powered Matching</span>
                </h2>

<div className="feature-grid">
  <div className={`feature-item feature-item--${isDark ? 'dark' : 'light'}`}>
    <div className={`icon-wrapper icon-wrapper--${isDark ? 'dark' : 'light'}`}>
      <Store color={isDark ? iconColors.store.dark : iconColors.store.light} size={24} />
    </div>
    <div className={`feature-text feature-text--${isDark ? 'dark' : 'light'}`}>Style Analysis</div>
    <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
      Our AI analyzes your product photos to understand the styles, complexity, and quality of garments you can manufacture.
    </p>
  </div>
  <div className={`feature-item feature-item--${isDark ? 'dark' : 'light'}`}>
    <div className={`icon-wrapper icon-wrapper--${isDark ? 'dark' : 'light'}`}>
      <Target color={isDark ? iconColors.target.dark : iconColors.target.light} size={24} />
    </div>
    <div className={`feature-text feature-text--${isDark ? 'dark' : 'light'}`}>Smart Matching</div>
    <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
      When brands upload their designs, we match them with manufacturers who have demonstrated ability to produce similar styles.
    </p>
  </div>
  <div className={`feature-item feature-item--${isDark ? 'dark' : 'light'}`}>
    <div className={`icon-wrapper icon-wrapper--${isDark ? 'dark' : 'light'}`}>
      <Factory color={isDark ? iconColors.factory.dark : iconColors.factory.light} size={24} />
    </div>
    <div className={`feature-text feature-text--${isDark ? 'dark' : 'light'}`}>Quality Recognition</div>
    <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
      Our algorithm assesses the quality and finish of your manufactured garments to ensure precise brand-manufacturer matches.
    </p>
  </div>
  <div className={`feature-item feature-item--${isDark ? 'dark' : 'light'}`}>
    <div className={`icon-wrapper icon-wrapper--${isDark ? 'dark' : 'light'}`}>
      <Scissors color={isDark ? iconColors.scissors.dark : iconColors.scissors.light} size={24} />
    </div>
    <div className={`feature-text feature-text--${isDark ? 'dark' : 'light'}`}>Capability Showcase</div>
    <p className={`process-text process-text--${isDark ? 'dark' : 'light'}`}>
      Your product photos help brands instantly understand your manufacturing capabilities and quality standards.
    </p>
  </div>
</div>
</div>

          </Col>

          <Col lg={6}>
            <div className={`content-section content-section--${isDark ? 'dark' : 'light'}`}>
            <h2 className={`section-title section-title--${isDark ? 'dark' : 'light'}`}>
            <span className="title-underline">Submit Your Manufacturer</span>
            </h2>

              {renderFormStep()}

              {step < 4 && (
                <div className="navigation-buttons">
                  {step > 1 && (
                    <button 
                      className={`button button--secondary button--${isDark ? 'dark' : 'light'}`}
                      onClick={() => setStep(step - 1)}
                    >
                      <ChevronLeft size={20} />
                      Back
                    </button>
                  )}
                  <button
                    className="button button--primary"
                    onClick={step === 3 ? handleSubmit : handleNextStep}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : step === 3 ? 'Submit' : (
                      <>
                        Next
                        <ChevronRight size={20} />
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ManuContact;