import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button, Image, Dropdown } from 'react-bootstrap';
import { LogOut, Sun, Moon, User } from 'lucide-react';
import { AppContext } from '../AppContext';
import './styles/GlobalNavbar.css';

const GlobalNavbar = () => {
  const { 
    isDark, 
    toggleDarkMode, 
    isAuthenticated, 
    handleLogout, 
    userEmail,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const navLinks = useMemo(() => [
    { path: '/home', label: 'Home', showAlways: true },
    { path: '/about', label: 'About', showAlways: true },
    { path: '/sketch', label: 'Upload', requireAuth: true },
    { path: '/history', label: 'Submissions', requireAuth: true },
    { path: '/manufacturers', label: 'Manufacturers', requireAuth: true },
    { path: '/ManuContact', label: 'Join Us!', requireAuth: true },

  ], []);

  const filteredNavLinks = navLinks.filter(link => 
    link.showAlways || (link.requireAuth === isAuthenticated)
  );

  const handleLogin = () => {
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(() => {
      navigate('/login');
      setIsAnimating(false);
    }, 300);
  };

  const handleLogoutAndRedirect = async () => {
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(async () => {
      await handleLogout();
      navigate('/home');
      setIsAnimating(false);
    }, 300);
  };

  const logoSrc = isDark 
    ? require("../images/logo/pomu-logo-invert.png") 
    : require("../images/logo/pomu-logo.png");

  const handleMenuToggle = () => {
    setIsAnimating(true);
    setIsOpen(!isOpen);
    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };

  const handleNavLinkClick = () => {
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(() => {
      setIsAnimating(false);
    }, 300);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isOpen]);

  return (
    <Navbar 
      expand="lg" 
      className={`navbar ${isDark ? 'dark' : ''} ${isAnimating ? 'animating' : ''}`}
      expanded={isOpen}
      style={{
        backgroundColor: isDark ? '#212121' : '#f8f9fa',
        color: isDark ? '#ffffff' : '#212121',
      }}
    >
      <Container>
        <Navbar.Brand as={Link} to="/home">
          <Image src={logoSrc} alt="Pomu Logo" fluid />
        </Navbar.Brand>
        
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          onClick={handleMenuToggle}
          className={isOpen ? 'active' : ''}
        />
        
        <Navbar.Collapse id="basic-navbar-nav" className={isAnimating ? 'animating' : ''}>
          <Nav className="me-auto">
            {filteredNavLinks.map((link) => (
              <Nav.Link 
                key={link.path} 
                as={Link} 
                to={link.path} 
                className="nav-link-custom"
                onClick={handleNavLinkClick}
                style={{
                  color: isDark ? '#ffffff' : '#212121',
                  fontWeight: location.pathname === link.path ? 'bold' : 'normal',
                }}
              >
                {link.label}
              </Nav.Link>
            ))}
          </Nav>

          <div className="auth-buttons">
            {isAuthenticated ? (
              <Dropdown align="end">
                <Dropdown.Toggle 
                  id="user-dropdown"
                  className="button-custom"
                  style={{
                    backgroundColor: isDark ? '#212121' : '#f8f9fa',
                    color: isDark ? '#ffffff' : '#212121',
                  }}
                >
                  <User size={16} className="me-2" />
                  {userEmail}
                </Dropdown.Toggle>
                <Dropdown.Menu 
                  style={{
                    backgroundColor: isDark ? '#212121' : '#f8f9fa',
                  }}
                >
                  <Dropdown.Item 
                    as={Link} 
                    to="/profile" 
                    className="dropdown-item-custom"
                    onClick={handleNavLinkClick}
                  >
                    <User size={16} className="me-2" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item 
                    onClick={handleLogoutAndRedirect}
                    className="dropdown-item-custom"
                  >
                    <LogOut size={16} className="me-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <Button 
                  variant={isDark ? "outline-light" : "outline-dark"} 
                  onClick={handleLogin} 
                  className="button-custom"
                  size="sm"
                >
                  Login
                </Button>
                <Button 
                  as={Link} 
                  to="/create-account" 
                  variant={isDark ? "light" : "dark"}
                  className="button-custom"
                  size="sm"
                  onClick={handleNavLinkClick}
                >
                  Sign Up
                </Button>
              </>
            )}
            
            <div className="ms-3 mt-3 mt-lg-0">
              <div
                onClick={toggleDarkMode}
                className="toggle-button"
                style={{
                  backgroundColor: isDark ? '#4a6785' : '#b8c9e0',
                }}
              >
                <div
                  className="toggle-button-circle"
                  style={{
                    backgroundColor: isDark ? '#212121' : '#f8f9fa',
                    marginLeft: isDark ? 'auto' : '0',
                  }}
                >
                  {isDark ? <Moon size={12} /> : <Sun size={12} />}
                </div>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GlobalNavbar;