/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_f4ZsEoSnv",
    "aws_user_pools_web_client_id": "198th7edombhl7fjmg7belng58",
    "oauth": {
        "domain": "pomu-demo.auth.us-east-2.amazoncognito.com",
        "scope": ["email", "profile", "openid"],
        "redirectSignIn": "http://localhost:3000/oauth-callback,https://pomu.io/oauth-callback",
        "redirectSignOut": "http://localhost:3000,https://pomu.io",
        "responseType": "code",
        "options": {
            "AdvancedSecurityDataCollectionFlag": false
        },
        "customParameters": {
            "google": {
                "prompt": "select_account"
            }
        }
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsmobile;